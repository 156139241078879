import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { CareerAgentBanner } from "@src/appV2/CareerAgent/CareerAgentBanner";
import { useCareerAgentConfig } from "@src/appV2/CareerAgent/useCareerAgentConfig";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { AssessmentBanner } from "@src/appV2/SkillsAssessment/components/AssessmentBanner";
import { useAssessmentsBannerText } from "@src/appV2/SkillsAssessment/utils/useAssessmentsBannerText";
import { useRef } from "react";

import { WorkplaceReferralsBanner } from "./WorkplaceReferralsBanner";
import { WorkerReferralBanner } from "../../appV2/Accounts/WorkerReferrals/components/WorkerReferralBanner";

interface Props {
  workerId: string;
}

export function AccountPageBanners(props: Props) {
  const { workerId } = props;

  const ldFlags = useCbhFlags();
  const workplaceReferralsConfig = ldFlags[CbhFeatureFlag.WORKPLACE_REFERRALS] ?? {};
  const isWorkerToWorkplaceReferralsEnabled = workplaceReferralsConfig.workerToWorkplace ?? false;
  const { referAndEarnBannerIsVisible } = useCbhFlag(
    CbhFeatureFlag.WORKER_TO_WORKER_REFERRAL_ENABLED_BANNERS,
    {
      defaultValue: { enterReferralCodeBannerIsVisible: false, referAndEarnBannerIsVisible: false },
    }
  );
  const careerAgentConfig = useCareerAgentConfig();

  // FIXME @HaileyesusZ: This ref will be removed once the workplace and worker referral experiments are
  // done and the markets are identified.
  // Right now, some markets are shared and we want to randomly order the banners in My Accounts page.
  const workerBannerIsShownFirstRef = useRef(Boolean(Math.round(Math.random())));

  const assessmentsBannerText = useAssessmentsBannerText({ workerId });

  if (isDefined(assessmentsBannerText)) {
    return (
      <Box padding={1}>
        <AssessmentBanner title={assessmentsBannerText} />
      </Box>
    );
  }

  if (careerAgentConfig.showBanner) {
    return (
      <Box padding={1}>
        <CareerAgentBanner />
      </Box>
    );
  }

  if (!isWorkerToWorkplaceReferralsEnabled && !referAndEarnBannerIsVisible) {
    return null;
  }

  if (referAndEarnBannerIsVisible && !isWorkerToWorkplaceReferralsEnabled) {
    return (
      <Box padding={1}>
        <WorkerReferralBanner />
      </Box>
    );
  }
  if (isWorkerToWorkplaceReferralsEnabled && !referAndEarnBannerIsVisible) {
    return (
      <Box padding={1}>
        <WorkplaceReferralsBanner />
      </Box>
    );
  }

  const banners = [
    <Box key={1} flexBasis="80%" flexShrink={0}>
      <WorkplaceReferralsBanner />
    </Box>,
    <Box key={0} flexBasis="80%" flexShrink={0}>
      <WorkerReferralBanner isFullHeight />
    </Box>,
  ];

  if (workerBannerIsShownFirstRef.current) {
    banners.reverse();
  }

  return (
    <Stack direction="row" justifyContent="space-between" overflow="auto" spacing={2} padding={2}>
      {...banners}
    </Stack>
  );
}
